import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const SlackIcon = React.memo(function SlackIcon(props: TSlackIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Slack" {...props}>
            <path d="M6.35996 13.1085C6.35996 14.0333 5.60299 14.7902 4.6782 14.7902C3.7534 14.7902 3 14.0333 3 13.1085C3 12.1837 3.75697 11.4267 4.68177 11.4267H6.36353V13.1085H6.35996ZM7.2062 13.1085C7.2062 12.1837 7.96318 11.4267 8.88797 11.4267C9.81276 11.4267 10.5697 12.1837 10.5697 13.1085V17.3147C10.5697 18.2395 9.81276 18.9964 8.88797 18.9964C7.96318 18.9964 7.2062 18.2395 7.2062 17.3147V13.1085ZM8.88797 6.35996C7.96318 6.35996 7.2062 5.60299 7.2062 4.6782C7.2062 3.7534 7.96318 3 8.88797 3C9.81276 3 10.5697 3.75697 10.5697 4.68177V6.36353H8.88797V6.35996ZM8.88797 7.2062C9.81276 7.2062 10.5697 7.96318 10.5697 8.88797C10.5697 9.81276 9.81276 10.5697 8.88797 10.5697H4.68177C3.75697 10.5697 3 9.81276 3 8.88797C3 7.96318 3.75697 7.2062 4.68177 7.2062H8.88797ZM15.6365 8.88797C15.6365 7.96318 16.3934 7.2062 17.3182 7.2062C18.243 7.2062 19 7.96318 19 8.88797C19 9.81276 18.243 10.5697 17.3182 10.5697H15.6365V8.88797ZM14.7902 8.88797C14.7902 9.81276 14.0333 10.5697 13.1085 10.5697C12.1837 10.5697 11.4267 9.81276 11.4267 8.88797V4.68177C11.4267 3.75697 12.1837 3 13.1085 3C14.0333 3 14.7902 3.75697 14.7902 4.68177V8.88797ZM13.1085 15.6365C14.0333 15.6365 14.7902 16.3934 14.7902 17.3182C14.7902 18.243 14.0333 19 13.1085 19C12.1837 19 11.4267 18.243 11.4267 17.3182V15.6365H13.1085ZM13.1085 14.7902C12.1837 14.7902 11.4267 14.0333 11.4267 13.1085C11.4267 12.1837 12.1837 11.4267 13.1085 11.4267H17.3147C18.2395 11.4267 18.9964 12.1837 18.9964 13.1085C18.9964 14.0333 18.2395 14.7902 17.3147 14.7902H13.1085Z" />
        </IconBase>
    );
});

export default SlackIcon;

// #region Types

export type TSlackIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <SlackIcon />
        : {
              ...IconBaseExample,
              render: SlackIcon,
              tags: ['logo', 'app'],
          };

// #endregion
