import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const FacebookIcon = React.memo(function FacebookIcon(props: TFacebookIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Facebook" {...props}>
            <path d="M19 11C19 6.58125 15.4187 3 11 3C6.58125 3 3 6.58125 3 11C3 14.75 5.58437 17.9 9.06875 18.7656V13.4438H7.41875V11H9.06875V9.94688C9.06875 7.225 10.3 5.9625 12.975 5.9625C13.4813 5.9625 14.3562 6.0625 14.7156 6.1625V8.375C14.5281 8.35625 14.2 8.34375 13.7906 8.34375C12.4781 8.34375 11.9719 8.84062 11.9719 10.1312V11H14.5844L14.1344 13.4438H11.9688V18.9406C15.9313 18.4625 19 15.0906 19 11Z" />
        </IconBase>
    );
});

export default FacebookIcon;

// #region Types

export type TFacebookIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <FacebookIcon />
        : {
              ...IconBaseExample,
              render: FacebookIcon,
              tags: ['logo', 'app'],
          };

// #endregion
