import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const InstagramIcon = React.memo(function InstagramIcon(props: TInstagramIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Instagram" {...props}>
            <path d="M11.0055 7.41172C9.01797 7.41172 7.41484 9.01484 7.41484 11.0023C7.41484 12.9898 9.01797 14.593 11.0055 14.593C12.993 14.593 14.5961 12.9898 14.5961 11.0023C14.5961 9.01484 12.993 7.41172 11.0055 7.41172ZM11.0055 13.3367C9.72109 13.3367 8.67109 12.2898 8.67109 11.0023C8.67109 9.71484 9.71797 8.66797 11.0055 8.66797C12.293 8.66797 13.3398 9.71484 13.3398 11.0023C13.3398 12.2898 12.2898 13.3367 11.0055 13.3367ZM15.5805 7.26484C15.5805 7.73047 15.2055 8.10234 14.743 8.10234C14.2773 8.10234 13.9055 7.72734 13.9055 7.26484C13.9055 6.80234 14.2805 6.42734 14.743 6.42734C15.2055 6.42734 15.5805 6.80234 15.5805 7.26484ZM17.9586 8.11484C17.9055 6.99297 17.6492 5.99922 16.8273 5.18047C16.0086 4.36172 15.0148 4.10547 13.893 4.04922C12.7367 3.98359 9.27109 3.98359 8.11484 4.04922C6.99609 4.10234 6.00234 4.35859 5.18047 5.17734C4.35859 5.99609 4.10547 6.98984 4.04922 8.11172C3.98359 9.26797 3.98359 12.7336 4.04922 13.8898C4.10234 15.0117 4.35859 16.0055 5.18047 16.8242C6.00234 17.643 6.99297 17.8992 8.11484 17.9555C9.27109 18.0211 12.7367 18.0211 13.893 17.9555C15.0148 17.9023 16.0086 17.6461 16.8273 16.8242C17.6461 16.0055 17.9023 15.0117 17.9586 13.8898C18.0242 12.7336 18.0242 9.27109 17.9586 8.11484ZM16.4648 15.1305C16.2211 15.743 15.7492 16.2148 15.1336 16.4617C14.2117 16.8273 12.0242 16.743 11.0055 16.743C9.98672 16.743 7.79609 16.8242 6.87734 16.4617C6.26484 16.218 5.79297 15.7461 5.54609 15.1305C5.18047 14.2086 5.26484 12.0211 5.26484 11.0023C5.26484 9.98359 5.18359 7.79297 5.54609 6.87422C5.78984 6.26172 6.26172 5.78984 6.87734 5.54297C7.79922 5.17734 9.98672 5.26172 11.0055 5.26172C12.0242 5.26172 14.2148 5.18047 15.1336 5.54297C15.7461 5.78672 16.218 6.25859 16.4648 6.87422C16.8305 7.79609 16.7461 9.98359 16.7461 11.0023C16.7461 12.0211 16.8305 14.2117 16.4648 15.1305Z" />
        </IconBase>
    );
});

export default InstagramIcon;

// #region Types

export type TInstagramIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <InstagramIcon />
        : {
              ...IconBaseExample,
              render: InstagramIcon,
              tags: ['logo', 'app'],
          };

// #endregion
