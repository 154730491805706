import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const BlueskyIcon = React.memo(function BlueskyIcon(props: TBlueskyIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Bluesky" {...props}>
            <path d="M7.03382 5.65013C8.63768 6.86028 10.3647 9.30955 11 10.626C11.6329 9.30955 13.3599 6.85786 14.9662 5.65013C16.1256 4.77815 18 4.10182 18 6.25158C18 6.68153 17.756 9.85545 17.6111 10.3699C17.1111 12.1622 15.2899 12.6187 13.6715 12.341C16.5024 12.824 17.2222 14.4255 15.6667 16.0269C12.7126 19.068 11.4203 15.2637 11.0894 14.2878C11.029 14.1067 11 14.0245 11 14.097C11 14.0221 10.971 14.1067 10.9106 14.2878C10.5797 15.2637 9.28744 19.068 6.33333 16.0269C4.77778 14.4255 5.49758 12.824 8.3285 12.341C6.71014 12.6163 4.88889 12.1598 4.38889 10.3675C4.24396 9.85303 4 6.67912 4 6.24917C4 4.10182 5.87681 4.77574 7.03382 5.64772V5.65013Z" />
        </IconBase>
    );
});

export default BlueskyIcon;

// #region Types

export type TBlueskyIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <BlueskyIcon />
        : {
              ...IconBaseExample,
              render: BlueskyIcon,
              tags: ['logo', 'app'],
          };

// #endregion
