import React, { useMemo } from 'react';
import { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';
import EnvelopeIcon from '@atlas/uicorn/lib/Icon/EnvelopeIcon';
import PhoneIcon from '@atlas/uicorn/lib/Icon/PhoneIcon';
import GlobeIcon from '@atlas/uicorn/lib/Icon/GlobeIcon';
import FacebookIcon from '@atlas/uicorn/lib/Icon/FacebookIcon';
import LinkedInIcon from '@atlas/uicorn/lib/Icon/LinkedInIcon';
import XTwitterIcon from '@atlas/uicorn/lib/Icon/XTwitterIcon';
import WhatsAppIcon from '@atlas/uicorn/lib/Icon/WhatsAppIcon';
import BlueskyIcon from '@atlas/uicorn/lib/Icon/BlueskyIcon';
import GitHubIcon from '@atlas/uicorn/lib/Icon/GitHubIcon';
import YCombinatorIcon from '@atlas/uicorn/lib/Icon/YCombinatorIcon';
import InstagramIcon from '@atlas/uicorn/lib/Icon/InstagramIcon';
import ThreadsIcon from '@atlas/uicorn/lib/Icon/ThreadsIcon';
import SlackIcon from '@atlas/uicorn/lib/Icon/SlackIcon';
import LinearIcon from '@atlas/uicorn/lib/Icon/LinearIcon';
import JiraIcon from '@atlas/uicorn/lib/Icon/JiraIcon';
import NetlifyIcon from '@atlas/uicorn/lib/Icon/NetlifyIcon';
import DiscordIcon from '@atlas/uicorn/lib/Icon/DiscordIcon';

const UrlIcon = React.memo(function UrlIcon(props: TUrlIconProps): React.ReactElement {
    const { url, ...elementProps } = props;

    const icon = useMemo(() => {
        if (!url) return null;

        if (url.startsWith('mailto:')) return 'mail';
        if (url.startsWith('tel:')) return 'phone';

        if (/^(https?:\/\/)?(www\.)?wa\.me(\/|\?|#)/i.test(url)) return 'whatsapp';
        if (/^(https?:\/\/)?(www\.)?(fb|facebook)\.com(\/|\?|#)/i.test(url)) return 'facebook';
        if (/^(https?:\/\/)?(www\.)?(linkedin\.com|linked\.in)(\/|\?|#)/i.test(url)) return 'linkedin';
        if (/^(https?:\/\/)?(www\.)?(x|twitter)\.com(\/|\?|#)/i.test(url)) return 'x';
        if (/^(https?:\/\/)?(www\.)?bsky\.app(\/|\?|#)/i.test(url)) return 'bluesky';
        if (/^(https?:\/\/)?(www\.)?((\w+\.)?github\.com|github\.dev)(\/|\?|#)/i.test(url)) return 'github';
        if (/^(https?:\/\/)?(www\.)?(\w+\.)?ycombinator\.com(\/|\?|#)/i.test(url)) return 'y-combinator';
        if (/^(https?:\/\/)?(www\.)?instagram\.com(\/|\?|#)/i.test(url)) return 'instagram';
        if (/^(https?:\/\/)?(www\.)?threads\.net(\/|\?|#)/i.test(url)) return 'threads';
        if (/^(https?:\/\/)?(www\.)?(\w+\.)?slack\.com(\/|\?|#)/i.test(url)) return 'slack';
        if (/^(https?:\/\/)?(www\.)?linear\.app(\/|\?|#)/i.test(url)) return 'linear';
        if (/^(https?:\/\/)?(www\.)?atlassian\.com(\/|\?|#)/i.test(url)) return 'jira';
        if (/^(https?:\/\/)?(www\.)?(\w+\.)?netlify\.(com|app)(\/|\?|#|$)/i.test(url)) return 'netlify';
        if (/^(https?:\/\/)?(www\.)?(\w+\.)?discord\.(com|gg)(\/|\?|#)/i.test(url)) return 'discord';

        return null;
    }, [url]);

    switch (icon) {
        case 'mail':
            return <EnvelopeIcon {...elementProps} />;
        case 'phone':
            return <PhoneIcon {...elementProps} />;
        case 'whatsapp':
            return <WhatsAppIcon {...elementProps} />;
        case 'facebook':
            return <FacebookIcon {...elementProps} />;
        case 'linkedin':
            return <LinkedInIcon {...elementProps} />;
        case 'x':
            return <XTwitterIcon {...elementProps} />;
        case 'bluesky':
            return <BlueskyIcon {...elementProps} />;
        case 'github':
            return <GitHubIcon {...elementProps} />;
        case 'y-combinator':
            return <YCombinatorIcon {...elementProps} />;
        case 'instagram':
            return <InstagramIcon {...elementProps} />;
        case 'threads':
            return <ThreadsIcon {...elementProps} />;
        case 'slack':
            return <SlackIcon {...elementProps} />;
        case 'linear':
            return <LinearIcon {...elementProps} />;
        case 'jira':
            return <JiraIcon {...elementProps} />;
        case 'netlify':
            return <NetlifyIcon {...elementProps} />;
        case 'discord':
            return <DiscordIcon {...elementProps} />;
        default:
            return <GlobeIcon {...elementProps} />;
    }
});

export default UrlIcon;

// #region Types

export type TUrlIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>> & {
        url?: string;
    };

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? (p: TUrlIconProps) => <UrlIcon {...p} />
        : {
              ...IconBaseExample,
              render: UrlIcon,
              controls: {
                  url: [
                      'regular-website.com', // Globe icon
                      'mailto:help@atlas.so', // Envelope icon
                      'tel:+1234567890', // Phone icon
                      'https://wa.me/1234567890', // WhatsApp icon
                      'x.com/_termosa', // X icon
                      'linked.in/company/atlas-support-inc', // LinkedIn icon
                      'fb.com/stanislav.termosa', // Facebook icon
                      'bsky.app/atlas', // Bluesky icon
                      'github.com/atlas-support-inc/flutter-sdk', // GitHub icon
                      'news.ycombinator.com/newcomments', // Y Combinator icon
                      'https://www.instagram.com/reel/DCcxZ94NILR/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==', // Instagram icon
                      'https://www.threads.net/@nictohme/post/DChyMyUTV9T?xmt=AQGzWqhvf7TU73j0QrWuwBNsmuPSTQxeSFbCY_Ga7wbhMw', // Threads icon
                      'https://supportosworkspace.slack.com/archives/C022WTKES66/p1731002535789249', // Slack icon
                      'https://linear.app/atlas-support/issue/ENG-5337/add-support-for-footer', // Linear icon
                      'atlassian.com/something', // Jira icon
                      'netlify.com', // Netlify icon
                      'discord.gg/atlas', // Discord icon
                  ],
                  ...IconBaseExample.controls,
              },
          };

// #endregion
