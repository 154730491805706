import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const EnvelopeIcon = React.memo(function EnvelopeIcon(props: TEnvelopeIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Envelope" {...props}>
            <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M4.5 8.416V16.5h13V8.416l-5.361 4.596a1.75 1.75 0 0 1-2.278 0L4.5 8.416ZM16.264 7.5H5.736l5.101 4.373a.25.25 0 0 0 .326 0L16.264 7.5ZM3 7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7Z"
            />
        </IconBase>
    );
});

export default EnvelopeIcon;

// #region Types

export type TEnvelopeIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function' ? () => <EnvelopeIcon /> : { ...IconBaseExample, render: EnvelopeIcon };

// #endregion
