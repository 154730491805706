import normalizeCssSize from '@atlas/tool/box/normalizeCssSize';
import type { TThemeColor } from '@atlas/uicorn/lib/System/StylesProvider';
import type { TExample } from '@atlas/uicorn/preview/modules';
import React from 'react';
import styled from 'styled-components';

const IconBase = React.memo(
    React.forwardRef(function IconBase(
        props: TIconBaseProps,
        ref: React.ForwardedRef<TIconBaseRef>,
    ): React.ReactElement {
        const { size, color, background, ...elementProps } = props;

        return (
            <SIconBase
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 22"
                fill="currentColor"
                $size={size}
                $color={color}
                $background={background}
                {...elementProps}
                ref={ref}
            />
        );
    }),
);

export default IconBase;

// #region Styles

export const SIconBase = styled.svg<{ $size?: number | string; $color?: string; $background?: string }>`
    --icon-size: ${(p) => (typeof p.$size === 'undefined' ? '1.692em' : normalizeCssSize(p.$size))};

    width: var(--icon-size);
    height: var(--icon-size);

    color: ${(p) => (p.$color && (p.theme.color[p.$color as TThemeColor] || p.$color)) ?? 'inherit'};
    background: ${(p) =>
        (p.$background && (p.theme.color[p.$background as TThemeColor] || p.$background)) ?? 'transparent'};

    line-height: 1;
    vertical-align: middle;
`;

// #endregion

// #region Types

export type TIconBaseRef = SVGSVGElement;

type TIconBaseRequiredProps = 'aria-label' | 'children';

export type TIconBaseProps = Omit<
    React.SVGAttributes<SVGElement>,
    TIconBaseRequiredProps | 'width' | 'height' | 'color'
> &
    Required<Pick<React.SVGAttributes<SVGElement>, TIconBaseRequiredProps>> & {
        size?: number;
        color?: TThemeColor | string;
        background?: TThemeColor | string;
    };

// #endregion

// #region Examples

export const Example: TExample = {
    render(props) {
        return (
            <IconBase aria-label="Icon" {...props}>
                <rect width={22} height={22} />
            </IconBase>
        );
    },
    controls: {
        color: { type: 'color', initial: 'grey9' },
        background: { type: 'color', initial: 'grey6' },
        size: { type: 'number', initial: 60 },
    },
};

// #endregion
