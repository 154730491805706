import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const LinkedInIcon = React.memo(function LinkedInIcon(props: TLinkedInIconProps): React.ReactElement {
    return (
        <IconBase aria-label="LinkedIn" {...props}>
            <path d="M17 4H4.99687C4.44687 4 4 4.45313 4 5.00938V16.9906C4 17.5469 4.44687 18 4.99687 18H17C17.55 18 18 17.5469 18 16.9906V5.00938C18 4.45313 17.55 4 17 4ZM8.23125 16H6.15625V9.31875H8.23438V16H8.23125ZM7.19375 8.40625C6.52812 8.40625 5.99063 7.86563 5.99063 7.20312C5.99063 6.54062 6.52812 6 7.19375 6C7.85625 6 8.39687 6.54062 8.39687 7.20312C8.39687 7.86875 7.85937 8.40625 7.19375 8.40625ZM16.0094 16H13.9344V12.75C13.9344 11.975 13.9187 10.9781 12.8562 10.9781C11.775 10.9781 11.6094 11.8219 11.6094 12.6938V16H9.53438V9.31875H11.525V10.2312H11.5531C11.8312 9.70625 12.5094 9.15312 13.5188 9.15312C15.6188 9.15312 16.0094 10.5375 16.0094 12.3375V16Z" />
        </IconBase>
    );
});

export default LinkedInIcon;

// #region Types

export type TLinkedInIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <LinkedInIcon />
        : {
              ...IconBaseExample,
              render: LinkedInIcon,
              tags: ['logo', 'app'],
          };

// #endregion
