import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const XTwitterIcon = React.memo(function XTwitterIcon(props: TXTwitterIconProps): React.ReactElement {
    return (
        <IconBase aria-label="X" {...props}>
            <path d="M15.0248 4.67232H17.1725L12.4815 10.0326L18 17.3277H13.6801L10.2942 12.9044L6.4246 17.3277H4.27379L9.29031 11.5932L4 4.67232H8.42938L11.4867 8.71534L15.0248 4.67232ZM14.2703 16.0439H15.4598L7.7814 5.88918H6.50369L14.2703 16.0439Z" />
        </IconBase>
    );
});

export default XTwitterIcon;

// #region Types

export type TXTwitterIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <XTwitterIcon />
        : {
              ...IconBaseExample,
              render: XTwitterIcon,
              tags: ['logo', 'app'],
          };

// #endregion
