import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const JiraIcon = React.memo(function JiraIcon(props: TJiraIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Jira" {...props}>
            <path
                d="M10.18 3a.625.625 0 0 0-.625.625 4.403 4.403 0 0 0 1.33 3.153H6.403a.625.625 0 0 0-.625.625 4.403 4.403 0 0 0 1.33 3.153H2.624A.625.625 0 0 0 2 11.18a4.403 4.403 0 0 0 4.403 4.402h.264v.264a4.403 4.403 0 0 0 4.402 4.403c.346 0 .625-.28.625-.625v-4.482l.04.04a4.403 4.403 0 0 0 3.113 1.29c.345 0 .625-.28.625-.626v-4.482l.04.04a4.403 4.403 0 0 0 3.113 1.29c.345 0 .625-.28.625-.626V3.625A.625.625 0 0 0 18.625 3H10.18Zm4.042 5.028v7.131a3.153 3.153 0 0 1-2.527-3.06v-.918a.625.625 0 0 0-.626-.625h-.889a3.153 3.153 0 0 1-3.09-2.528h7.132Zm1.25.295A3.153 3.153 0 0 0 18 11.382V4.25h-7.132a3.154 3.154 0 0 0 3.09 2.528h.889a.627.627 0 0 1 .625.625v.92Zm-5.028 3.746v-.263H3.313a3.152 3.152 0 0 0 3.09 2.527h.889c.345 0 .625.28.625.625v.89a3.153 3.153 0 0 0 2.527 3.09v-6.869Z"
                clipRule="evenodd"
                fillRule="evenodd"
            />
        </IconBase>
    );
});

export default JiraIcon;

// #region Types

export type TJiraIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <JiraIcon />
        : { ...IconBaseExample, render: JiraIcon, tags: ['logo', 'app'] };

// #endregion
