import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const ThreadsIcon = React.memo(function ThreadsIcon(props: TThreadsIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Threads" {...props}>
            <path d="M14.1621 10.4066C14.2263 10.4328 14.2846 10.462 14.3459 10.4882C15.1973 10.8994 15.8214 11.5147 16.148 12.2787C16.6058 13.3431 16.6495 15.0723 15.2644 16.4545C14.2088 17.5101 12.9228 17.9854 11.1061 18H11.0974C9.0503 17.9854 7.47854 17.2972 6.42001 15.9529C5.47812 14.7573 4.99406 13.0923 4.97656 11.0073V10.9985V10.9927C4.99114 8.90773 5.47521 7.24266 6.4171 6.04707C7.47563 4.70277 9.0503 4.01458 11.0974 4H11.1061C13.1561 4.01458 14.7483 4.69985 15.8389 6.03833C16.3754 6.70027 16.772 7.49635 17.0228 8.42075L15.8447 8.73568C15.6377 7.98334 15.3257 7.3418 14.9057 6.82858C14.0543 5.78463 12.777 5.24807 11.1003 5.23641C9.43813 5.25099 8.18131 5.78463 7.3619 6.82275C6.59789 7.79379 6.20131 9.19933 6.18673 10.9985C6.20131 12.7978 6.59789 14.2033 7.3619 15.1773C8.1784 16.2154 9.43813 16.749 11.1003 16.7636C12.5991 16.7519 13.5906 16.3962 14.4158 15.5709C15.3577 14.632 15.3402 13.4772 15.0399 12.7744C14.862 12.3603 14.5412 12.0162 14.1097 11.7567C14.0018 12.5411 13.7656 13.1652 13.3894 13.6463C12.8907 14.282 12.1821 14.6261 11.2694 14.6757C10.5812 14.7136 9.91928 14.5474 9.40606 14.2091C8.79952 13.8067 8.44376 13.1943 8.40585 12.4799C8.33295 11.0714 9.44688 10.0596 11.1819 9.96042C11.7972 9.92543 12.3746 9.95168 12.9082 10.0421C12.8383 9.6105 12.6954 9.2664 12.4825 9.01562C12.1909 8.67444 11.736 8.49948 11.1353 8.49656H11.1149C10.6308 8.49656 9.9776 8.6307 9.56061 9.26349L8.55748 8.5753C9.11737 7.72672 10.0243 7.26015 11.1178 7.26015H11.1411C12.9666 7.27182 14.0543 8.412 14.1651 10.4007L14.1592 10.4066H14.1621ZM9.6131 12.4128C9.65101 13.1448 10.4413 13.4859 11.2053 13.4422C11.9518 13.4014 12.7974 13.1098 12.9403 11.3076C12.5554 11.2231 12.1297 11.1793 11.6748 11.1793C11.5348 11.1793 11.3948 11.1823 11.2548 11.191C10.0038 11.261 9.58685 11.8675 9.61601 12.4099L9.6131 12.4128Z" />
        </IconBase>
    );
});

export default ThreadsIcon;

// #region Types

export type TThreadsIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <ThreadsIcon />
        : {
              ...IconBaseExample,
              render: ThreadsIcon,
              tags: ['logo', 'app'],
          };

// #endregion
