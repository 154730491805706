import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const YCombinatorIcon = React.memo(function YCombinatorIcon(props: TYCombinatorIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Y Combinator" {...props}>
            <path d="M4 4V18H18V4H4ZM4.6625 10.1625H4.65625C4.65938 10.1594 4.6625 10.1531 4.66562 10.15C4.66562 10.1531 4.66563 10.1594 4.6625 10.1625ZM11.475 11.8469V15H10.4938V11.7906L8 7H9.16563C10.8063 10.0719 10.7031 10.1625 11.0188 10.925C11.4031 10.0813 11.2 10.1625 12.9125 7H14L11.475 11.8469Z" />
        </IconBase>
    );
});

export default YCombinatorIcon;

// #region Types

export type TYCombinatorIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <YCombinatorIcon />
        : {
              ...IconBaseExample,
              render: YCombinatorIcon,
              tags: ['logo', 'app'],
          };

// #endregion
