import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const PhoneIcon = React.memo(function PhoneIcon(props: TPhoneIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Phone" {...props}>
            <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="m12.457 12.386 1.425-1.425a1.723 1.723 0 0 1 2.436 0l2.828 2.828a1.723 1.723 0 0 1 0 2.436l-2.131 2.131-.003.004c-.705.703-1.81 1.109-2.937.734-1.39-.462-3.985-1.632-6.793-4.44-2.807-2.808-3.977-5.403-4.44-6.792-.375-1.13.033-2.236.738-2.94L5.71 2.79a1.723 1.723 0 0 1 2.437 0l2.828 2.828a1.723 1.723 0 0 1 0 2.436L9.55 9.48a58.4 58.4 0 0 0 2.907 2.907Zm2.486-.364a.222.222 0 0 1 .314 0l2.828 2.828a.222.222 0 0 1 0 .315l-2.13 2.13c-.375.375-.905.542-1.407.376-1.197-.399-3.582-1.456-6.205-4.078-2.622-2.622-3.679-5.007-4.077-6.205-.167-.501 0-1.032.374-1.406l2.132-2.13a.223.223 0 0 1 .314 0l2.828 2.827a.223.223 0 0 1 0 .315L7.626 9.282a.222.222 0 0 0-.008.304 59.852 59.852 0 0 0 4.732 4.733.222.222 0 0 0 .305-.01l2.288-2.287Z"
            />
        </IconBase>
    );
});

export default PhoneIcon;

// #region Types

export type TPhoneIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function' ? () => <PhoneIcon /> : { ...IconBaseExample, render: PhoneIcon };

// #endregion
