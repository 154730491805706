import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const NetlifyIcon = React.memo(function NetlifyIcon(props: TNetlifyIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Netlify" viewBox="0 0 19 16" {...props}>
            <path d="M4.90184 13.3377H4.73184L3.88184 12.4882V12.3183L5.18517 11.0156H6.09184L6.21934 11.1431V12.0351L4.90184 13.3377Z" />
            <path d="M3.88184 3.68353V3.51362L4.73184 2.66406H4.90184L6.20517 3.96672V4.87291L6.09184 4.98619H5.18517L3.88184 3.68353Z" />
            <path d="M11.404 10.5964H10.1574L10.044 10.4831V7.58043C10.044 7.05654 9.84572 6.66008 9.22238 6.64592C8.89655 6.63176 8.52822 6.64592 8.14572 6.66008L8.08905 6.71672V10.4831L7.98988 10.5822H6.74322L6.62988 10.4831V5.51318L6.72905 5.41406H9.53405C10.6249 5.41406 11.5032 6.29194 11.5032 7.3822V10.4831L11.404 10.5964Z" />
            <path d="M5.07167 8.7255H0.0991667L0 8.62638V7.38036L0.0991667 7.28125H5.07167L5.17083 7.38036V8.62638L5.07167 8.7255Z" />
            <path d="M18.0346 8.7255H13.0621L12.9629 8.62638V7.38036L13.0621 7.28125H18.0346L18.1337 7.38036V8.62638L18.0346 8.7255Z" />
            <path d="M8.34375 3.85426V0.130365L8.44292 0.03125H9.68958L9.78875 0.130365V3.85426L9.70375 3.96753H8.45708L8.34375 3.85426Z" />
            <path d="M8.34375 15.8762V12.1382L8.44292 12.0391H9.68958L9.78875 12.1382V15.8621L9.68958 15.9612H8.44292L8.34375 15.8762Z" />
        </IconBase>
    );
});

export default NetlifyIcon;

// #region Types

export type TNetlifyIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <NetlifyIcon />
        : { ...IconBaseExample, render: NetlifyIcon, tags: ['logo', 'app'] };

// #endregion
