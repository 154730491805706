import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';
import styled from 'styled-components';

const GlobeIcon = React.memo(function GlobeIcon(props: TGlobeIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Globe" {...props}>
            <SPath d="M7.5 12.4062C7.67773 12.4062 8.18555 12.2285 8.69336 11.1875C8.92188 10.7305 9.125 10.1973 9.25195 9.5625H5.72266C5.875 10.1973 6.05273 10.7305 6.28125 11.1875C6.81445 12.2285 7.29688 12.4062 7.5 12.4062ZM5.51953 8.34375H9.45508C9.50586 7.96289 9.53125 7.55664 9.53125 7.125C9.53125 6.71875 9.50586 6.3125 9.45508 5.90625H5.51953C5.46875 6.3125 5.46875 6.71875 5.46875 7.125C5.46875 7.55664 5.46875 7.96289 5.51953 8.34375ZM5.72266 4.6875H9.25195C9.125 4.07812 8.92188 3.54492 8.69336 3.08789C8.18555 2.04688 7.67773 1.84375 7.5 1.84375C7.29688 1.84375 6.81445 2.04688 6.28125 3.08789C6.05273 3.54492 5.875 4.07812 5.72266 4.6875ZM10.6738 5.90625C10.7246 6.3125 10.7246 6.71875 10.7246 7.125C10.7246 7.55664 10.7246 7.96289 10.6738 8.34375H12.6289C12.7305 7.96289 12.7812 7.55664 12.7812 7.125C12.7812 6.71875 12.7305 6.3125 12.6289 5.90625H10.6738ZM12.1719 4.6875C11.6387 3.64648 10.75 2.80859 9.68359 2.32617C10.0391 2.98633 10.3184 3.79883 10.4961 4.6875H12.1719ZM4.47852 4.6875C4.65625 3.79883 4.93555 2.98633 5.29102 2.32617C4.22461 2.80859 3.33594 3.64648 2.80273 4.6875H4.47852ZM2.3457 5.90625C2.26953 6.3125 2.21875 6.71875 2.21875 7.125C2.21875 7.55664 2.24414 7.96289 2.3457 8.34375H4.30078C4.25 7.96289 4.25 7.55664 4.25 7.125C4.25 6.71875 4.25 6.3125 4.30078 5.90625H2.3457ZM9.68359 11.9492C10.75 11.4668 11.6387 10.6289 12.1719 9.5625H10.4961C10.3184 10.4766 10.0391 11.2891 9.68359 11.9492ZM5.29102 11.9492C4.93555 11.2891 4.65625 10.4766 4.47852 9.5625H2.80273C3.33594 10.6289 4.22461 11.4668 5.29102 11.9492ZM7.5 13.625C5.16406 13.625 3.03125 12.4062 1.86328 10.375C0.695312 8.36914 0.695312 5.90625 1.86328 3.875C3.03125 1.86914 5.16406 0.625 7.5 0.625C9.81055 0.625 11.9434 1.86914 13.1113 3.875C14.2793 5.90625 14.2793 8.36914 13.1113 10.375C11.9434 12.4062 9.81055 13.625 7.5 13.625Z" />
        </IconBase>
    );
});

export default GlobeIcon;

// #region Styles

const SPath = styled.path`
    translate: 16% 18%;
`;

// #endregion

// #region Types

export type TGlobeIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function' ? () => <GlobeIcon /> : { ...IconBaseExample, render: GlobeIcon };

// #endregion
