import { GetServerSidePropsContext } from "next";
import api from "./api";

type HelpCenterLink = {
  id: string;
  url: string;
  label: string;
  icon?: string;
};

export type HelpCenterFooterLink = {
  id: string;
  url: string;
  label?: string;
  icon?: string;
};

export enum HelpCenterFooterLinksMode {
  HIDDEN = 0,
  ICONS = 1,
  LABELS = 2,
  ICONS_AND_LABELS = 3,
}

type CompanySettingsResponse = {
  id: string;
  appId: string;
  companyId: string;
  slug: string;
  name: string;
  title?: string;
  primaryColor: string;
  secondaryColor: string;
  chatting: boolean;
  sessionRecording: boolean;
  redirectNotFound: boolean;
  links?: HelpCenterLink[];
  footerLinks?: HelpCenterFooterLink[];
  footerLinksMode: HelpCenterFooterLinksMode;
  showRelatedArticles: boolean;
  embeddedSnippets: TEmbeddedSnippet[] | null;
  enableAccountLevelSupportPortal: boolean;
  enableSupportPortal: boolean;
  showTableOfContents: boolean;
  feedback: "always" | "no results" | "never";
  supportPortalForms: TSupportPortalForm[];
  language?: string | null;
};

export type TEmbeddedSnippet = {
  enabled: boolean;
  label: string;
  snippet: string;
  placement: "HEAD" | "BODY_START" | "BODY_END";
  tagName: "SCRIPT" | "NOSCRIPT" | "STYLE";
};

export enum CustomerPortalFormFieldCategory {
  CONVERSATION = "CONVERSATION",
  CUSTOMER = "CUSTOMER",
}

export type TSupportPortalFormField = {
  key: string;
  label: string;
  category: CustomerPortalFormFieldCategory;
  required?: boolean;
  type?: "core" | "custom";
};

export type TSupportPortalForm = {
  key: string;
  title: string;
  description?: string;
  channel: number;
  numSubmissions: number;
  fields: TSupportPortalFormField[];
};

export type CompanySettings = {
  id: string;
  appId: string;
  companyId: string;
  slug: string;
  name: string;
  pageTitle?: string;
  title?: string;
  logoUrl?: string;
  logoWidth?: number;
  logoHeight?: number;
  faviconUrl?: string;
  primaryColor: string;
  secondaryColor: string;
  chatting: boolean;
  sessionRecording: boolean;
  redirectNotFound: boolean;
  customDomain?: string;
  helpCenterUrl?: string;
  links?: HelpCenterLink[];
  footerLinks?: HelpCenterFooterLink[];
  footerLinksMode: HelpCenterFooterLinksMode;
  showRelatedArticles: boolean;
  embeddedSnippets: TEmbeddedSnippet[];
  hcGoogleSiteVerificationCode?: string;
  language?: string;
  enableAccountLevelSupportPortal: boolean;
  enableSupportPortal: boolean;
  showTableOfContents: boolean;
  feedback: "always" | "no results" | "never";
  supportPortalForms: TSupportPortalForm[];
  supportPortalLoginUrl?: string;
  baseDomain?: string;
};

export default async function loadCompanySettings(
  options: { domain: string } | { slug: string },
  context?: GetServerSidePropsContext
): Promise<CompanySettings | undefined> {
  const path =
    "domain" in options
      ? `/helpcenter-public/settings-by-domain?domain=${encodeURIComponent(
          options.domain
        )}`
      : `/helpcenter-public/settings/${options.slug}`;

  return api<CompanySettingsResponse>({
    path,
    context,
  }).then(({ language, ...data }) => ({
    ...data,
    ...(language !== null && { language }),
    name: data.slug
      .split("-")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" "),
    embeddedSnippets: data.embeddedSnippets || [],
  }));
}
