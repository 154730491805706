import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const LinearIcon = React.memo(function LinearIcon(props: TLinearIconProps): React.ReactElement {
    return (
        <IconBase aria-label="Linear" {...props}>
            <path
                d="M5.343 16.657a7.963 7.963 0 0 1-2.208-4.188l6.396 6.396a7.963 7.963 0 0 1-4.188-2.208ZM3.201 9.212a8.03 8.03 0 0 0-.19 1.365l8.412 8.412a8.029 8.029 0 0 0 1.365-.19L3.201 9.212Zm12.083 8.547c-.373.236-.76.439-1.16.608L3.633 7.876a7.94 7.94 0 0 1 .608-1.16L15.284 17.76Zm1.373-1.102c-.119.119-.24.233-.365.343L5 5.708a8 8 0 1 1 11.656 10.95Zm-1.06-10.253a6.501 6.501 0 0 1 .5 8.632L6.963 5.904a6.501 6.501 0 0 1 8.632.5Z"
                clipRule="evenodd"
                fillRule="evenodd"
            />
        </IconBase>
    );
});

export default LinearIcon;

// #region Types

export type TLinearIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <LinearIcon />
        : { ...IconBaseExample, render: LinearIcon, tags: ['logo', 'app'] };

// #endregion
