import React from 'react';
import IconBase, { TIconBaseProps, Example as IconBaseExample } from '@atlas/uicorn/lib/Icon/IconBase';

const WhatsAppIcon = React.memo(function WhatsAppIcon(props: TWhatsAppIconProps): React.ReactElement {
    return (
        <IconBase aria-label="WhatsApp" viewBox="0 0 22 22" {...props}>
            <path d="M0.914307 20.8537L2.37246 15.5267C1.47268 13.9674 0.999877 12.1998 1.00074 10.3873C1.00333 4.72066 5.61463 0.109375 11.2804 0.109375C14.0299 0.110239 16.6109 1.18116 18.5522 3.12421C20.4926 5.06726 21.561 7.64992 21.5601 10.3968C21.5575 16.0643 16.9462 20.6756 11.2804 20.6756C9.56038 20.6747 7.86539 20.2434 6.36402 19.424L0.914307 20.8537ZM6.61641 17.5631C8.06505 18.4231 9.44801 18.9383 11.277 18.9391C15.9859 18.9391 19.8219 15.1066 19.8245 10.3951C19.8262 5.67403 16.0084 1.84671 11.2839 1.84498C6.57146 1.84498 2.73808 5.67749 2.73635 10.3882C2.73549 12.3113 3.29904 13.7513 4.2455 15.2579L3.38202 18.411L6.61641 17.5631ZM16.4587 12.8403C16.3948 12.7331 16.2236 12.6692 15.9661 12.5404C15.7093 12.4116 14.4465 11.7901 14.2106 11.7046C13.9755 11.619 13.8043 11.5758 13.6323 11.8334C13.4612 12.0901 12.9685 12.6692 12.819 12.8403C12.6694 13.0115 12.519 13.0331 12.2623 12.9043C12.0056 12.7755 11.1776 12.5049 10.1965 11.6294C9.43332 10.9483 8.9173 10.1073 8.76777 9.84968C8.61824 9.59297 8.75221 9.45381 8.88013 9.32589C8.99596 9.21093 9.13684 9.02596 9.26563 8.87556C9.39615 8.7269 9.4385 8.61972 9.52494 8.44771C9.61051 8.27657 9.56816 8.12618 9.50333 7.99739C9.4385 7.86947 8.92508 6.60493 8.71159 6.09064C8.50241 5.59019 8.29065 5.65761 8.13334 5.64983L7.64066 5.64118C7.46952 5.64118 7.1912 5.70515 6.9561 5.96272C6.72099 6.22029 6.05717 6.8409 6.05717 8.10543C6.05717 9.36997 6.9777 10.5913 7.10563 10.7624C7.23442 10.9336 8.91644 13.5283 11.4931 14.6407C12.1059 14.9052 12.5847 15.0634 12.9573 15.1818C13.5727 15.3772 14.1328 15.3495 14.5753 15.2838C15.0689 15.2103 16.0948 14.6624 16.3092 14.0625C16.5236 13.4618 16.5236 12.9475 16.4587 12.8403Z" />
        </IconBase>
    );
});

export default WhatsAppIcon;

// #region Types

export type TWhatsAppIconProps = Omit<TIconBaseProps, 'children' | 'aria-label'> &
    Partial<Pick<TIconBaseProps, 'aria-label'>>;

// #endregion

// #region Examples

export const Example =
    typeof IconBaseExample === 'function'
        ? () => <WhatsAppIcon />
        : { ...IconBaseExample, render: WhatsAppIcon, tags: ['logo', 'app'] };

// #endregion
